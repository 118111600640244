<template>
  <div class="board" v-if="model">
    <div class="boardrd first">
      <span>{{model.title}}</span>
    </div>
    <div class="boardrd">
      <span class="medal" :class="getMedalClass(model.memLevel)">
        <em class="level">{{model.memLevel}}</em>
      </span>
      <span class="nob">
        {{model.memNick}}
      </span>
      <span class="ml10 mr10">
        {{dateFormatForBoard(model.regDate)}}
      </span>
      <span>{{ $t('front.board.comment') }}<span class="nsicon ml5 rdbg" v-if="model.comment.length > 0">{{model.comment.length}}</span></span>
    </div>
    <div class="boardrd hauto">
      <pre>{{model.content}}</pre>
    </div>
  </div>
  <div class="boardbtn" v-if="model">
    <ui-button :text="$t('front.board.edit')" :className="'grsbtn right'" v-if="model.regId == userData.memId" @click="onClickEdit()"/>
    <ui-button :text="$t('front.board.delete')" :className="'grsbtn right mr5'" v-if="model.regId == userData.memId" @click="onClickRemove(model.boardIdx)"/>
  </div>

  <div class="boardcomment" v-if="model">
    <p class="commenttopnum" v-if="model.comment.length > 0">{{ $t('front.board.comment') }} <em>{{model.comment.length}}</em></p>
    <template v-for="comment in model.comment" v-bind:key="comment.boardIdx">
      <div class="commentwrap top">
        <h4>
          <span class="commenth">
            <span class="medal" :class="getMedalClass(comment.memLevel)"><em class="level">{{comment.memLevel}}</em></span>{{comment.memNick}}
          </span>
<!--          <span class="commentbtn">-->
<!--            <a class="rdsbtn mr5" v-if="comment.memId === userData.memId">수정하기</a>-->
<!--            <a class="grsbtn">취소하기</a>-->
<!--          </span>-->
        </h4>
        <div class="commentop">
          <span>{{comment.content}}</span>
          <span>{{dateFormatForBoard(comment.regDate)}}</span>
        </div>
        <template v-for="reComment in comment.comment" v-bind:key="reComment.boardIdx">
          <div class="commentre">
            <h4>
              <span class="commenth">
                <span class="medal" :class="getMedalClass(reComment.memLevel)"><em class="level">{{reComment.memLevel}}</em></span>{{reComment.memNick}}</span>
<!--              <span class="commentbtn">-->
<!--                <a class="blsbtn">답글달기</a>-->
<!--              </span>-->
            </h4>
            <div class="commentop2">
              <span>{{reComment.content}}</span>
              <span>{{dateFormatForBoard(reComment.regDate)}}</span>
            </div>
          </div>
        </template>

        <!--div class="commentbottom">
          <textarea :placeholder="$t('front.board.commentPlaceholder')" v-model="comment.replyContent"></textarea>
          <a class="commentb" @click="onSaveReply(comment.cmtIdx, comment.replyContent, 'C' )">{{ $t('front.board.commentWrite') }}</a>
        </div-->
      </div>
    </template>

    <div class="commentwrap">
      <h4><span class="commenth">{{ $t('front.board.commentWrites') }}<span class="nsicon rdbg ml10" v-if="model.comment.length > 0">{{model.comment.length}}</span></span></h4>
      <div class="commentbottom">
        <textarea :placeholder="$t('front.board.commentPlaceholder')" v-model="replyContent"></textarea>
        <a class="commentb" @click="onSaveReply(model.boardIdx, replyContent)">{{ $t('front.board.commentWrite') }}</a>
      </div>
    </div>
  </div>

  <div class="boardbtn pt60">
    <ui-button :text="$t('front.board.prev')" :className="'grsbtn left'" v-if="prevId" @click="onClickPrevNext('prev')"/>
    <ui-button :text="$t('front.board.list')" :className="'grbbtn'" @click="onClickList"/>
    <ui-button :text="$t('front.board.next')" :className="'grsbtn right'" v-if="nextId" @click="onClickPrevNext('next')"/>
  </div>
</template>

<script>

import UiButton from '@/components/ui/UiButton'
import { getBoardCookie } from '@/libs/auth-helper'

export default {
  name: 'BoardRead',
  components: {
    UiButton
  },
  data () {
    return {
      boardType: 'bbs',
      boardIdx: null,
      model: null,
      replyContent: null,
      prevId: null,
      nextId: null
    }
  },
  created () {
    this.boardIdx = this.$route.params.boardIdx
    this.loadBoardDetail(this.boardIdx)
    this.idxList = getBoardCookie().split(',')

    if (this.idxList.length > 0) {
      for (let i = 0, iLen = this.idxList.length; i < iLen; i++) {
        if (this.idxList[i] === this.boardIdx.toString()) {
          if (this.idxList[i - 1]) {
            this.prevId = this.idxList[i - 1]
          }
          if (this.idxList[i + 1]) {
            this.nextId = this.idxList[i + 1]
          }
        }
      }
    }
    console.log(this.boardIdx, this.prevId, this.nextId)
  },
  methods: {
    getMedalClass (level) {
      if (level < 10) {
        return 'medal' + level
      } else {
        return 'medallv'
      }
    },
    getMedalClassMobile (level) {
      if (level < 10) {
        return 'level' + level
      } else {
        return 'levellv'
      }
    },
    async onSaveReply (boardIdx, content, cmtType = 'B') {
      if (!content) {
        this.onAlert('warningart', 'front.board.emptyContent')
        return false
      }

      const params = {
        boardIdx: Number(boardIdx),
        cmtType: cmtType,
        content: content
      }

      const replyResult = await this.setSaveReply(params)
      if (replyResult) {
        this.replyContent = ''
        await this.loadBoardDetail(this.boardIdx)
      }
    },
    async loadBoardDetail (boardIdx) {
      const params = {
        boardIdx: boardIdx,
        boardType: this.boardType
      }
      this.model = await this.getBoardDetail(params)
      this.replyContent = ''
    },
    onClickPrevNext (type) {
      let id = this.prevId
      if (type === 'next') {
        id = this.nextId
      }

      this.$router.push({ name: 'boardRead', params: { boardIdx: id } })
    },
    onClickList () {
      // this.goBack()
      this.$router.push({ name: 'boardList' })
    },
    onClickEdit () {
      this.$router.push({ name: 'boardWrite', params: { boardIdx: this.boardIdx } })
    },
    onClickRemove (boardIdx) {
      if (boardIdx) {
        const params = {
          boardIdx: boardIdx,
          boardType: this.boardType,
          delYn: 'Y'
        }

        this.setSaveBoard(params, 'delete', () => {
          this.goPageByName('board')
        })
      }
    },
    onClickReplyRemove (cmtIdx) {
      if (cmtIdx) {
        const params = {
          cmtIdx: cmtIdx,
          boardType: this.boardType,
          delYn: 'Y'
        }

        this.setSaveBoard(params, 'delete', () => {
          this.goPageByName('board')
        })
      }
    }
  }
}
</script>
<style scoped>
.levelname {margin: 2px;border-radius: 16px;background: #e8f3fd;text-align: center;font-size: 12px;font-weight: bold;padding: 20px 0;}
.levelname .medal {width: 39px;height: 46px;background-size: 35px;}
.levelname .name {font-size: 16px;}
.levelname .medal .level {font-size: 16px;top: 5px;left: 0;width: 34px;}
.medal1 {background: url('~@/assets/img/medal.png') no-repeat;background-size: 34px;}
.medal2 {background: url('~@/assets/img/medal.png') no-repeat;background-size: 34px;}
.medal3 {background: url('~@/assets/img/medal.png') no-repeat;background-size: 34px;}
.medal4 {background: url('~@/assets/img/medal.png') no-repeat;background-size: 34px;}
.medal5 {background: url('~@/assets/img/medal.png') no-repeat;background-size: 34px;}
.medal6 {background: url('~@/assets/img/medal.png') no-repeat;background-size: 34px;}
.medal7 {background: url('~@/assets/img/medal.png') no-repeat;background-size: 34px;}
.medal8 {background: url('~@/assets/img/medal.png') no-repeat;background-size: 34px;}
.medal9 {background: url('~@/assets/img/medal.png') no-repeat;background-size: 34px;}
.medal {width: 34px;height: 27px;position: relative;display: inline-block;vertical-align: middle;}
.medal .level {position: absolute;top: 1px;left: 0;color: #000;font-size: 12px;width: 34px;text-align: center;line-height: 1.5em;}
.leveltxt {margin: 10px 0;font-size: 12px;}
.boardrd pre {white-space: pre-wrap;word-break: break-all;padding: 10px 0;line-height: 1.2em;}
</style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
